import React from 'react';
import { IPriceChange } from "../../interfaces/priceChange.interface";
import { Layout, Col, Table } from "antd";
import { CloseOutlined, BorderOutlined, CheckSquareOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/lib/table";
import Comments from "./cell/Comment"
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { FilterFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../reduxStore/reducers/rootReducer';
const { Content } = Layout;

const PriceChangeTable = (
    {
        priceChangeData,
        pdfSignClickHandler,
        genericAltSignClickHandler,
        commentsSubmitHandler,
        store
    }: {
        priceChangeData: IPriceChange[],
        pdfSignClickHandler: Function,
        genericAltSignClickHandler: Function,
        commentsSubmitHandler: Function,
        store: string
    }) => {

    const readOnlyState = useSelector(
        (state: RootState) => state.readOnly
    )

    const signKitColorsState = useSelector(
        (state: RootState) => state.signKitColors.colors
    )
    
    const getUniqueValuesComma = (data: any[], key: string | number) => {

        const uniqueValues = new Set();
        data.forEach(item => {
            if (Array.isArray(item[key])) {
                item[key].forEach((value: any) => uniqueValues.add(value));
            } else if (typeof item[key] === 'string') {
                item[key].split(',').forEach((value: string) => uniqueValues.add(value.trim()));
            } else {
                uniqueValues.add(item[key]);
            }
        });
        return Array.from(uniqueValues).map(value => ({
            text: value,
            value: value,
        }));
    };

    const columns: ColumnsType<IPriceChange> = [
        {
            title: "Brand",
            dataIndex: "brand",
            key: "brand",
            width: "10em",
            filters: getUniqueValuesComma(priceChangeData, 'brand') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => a.brand.localeCompare(b.brand),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { brand }) => (
                brand.replace(',', ', ')
            ),
            onFilter: (value, record) => String(record.brand).split(',').map(v => v.trim()).includes(String(value)),
        },
        {
            title: "SBU",
            dataIndex: "category",
            key: "category",
            width: "10em",
            filters: getUniqueValuesComma(priceChangeData, 'category') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => a.category.join(',').localeCompare(b.category.join(',')),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { category }) => (
                category.join(',')
            ),
            onFilter: (value, record) => String(record.category).split(',').map(v => v.trim()).includes(String(value)),
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
            width: "10em",
            filters: getUniqueValuesComma(priceChangeData, 'department') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => a.department.join(',').localeCompare(b.department.join(',')),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { department }) => (
                department.join(',')
            ),
            onFilter: (value, record) => String(record.department).split(',').map(v => v.trim()).includes(String(value)),
        },
        {
            title: "Collection",
            dataIndex: "collection",
            key: "collection",
            width: "10em",
            filters: getUniqueValuesComma(priceChangeData, 'collection') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => a.collection.localeCompare(b.collection),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            onFilter: (value, record) => record.collection.indexOf(String(value)) === 0,
        },
        {
            title: "Price Changed",
            dataIndex: "price_change",
            key: "price_change",
            align: "center",
            width: "7em",
            defaultSortOrder: 'descend',
            filters: [
                { text: 'Change', value: true },
                { text: 'No change', value: false },
            ],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => Number(a.price_change) - Number(b.price_change),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { price_change }) => (
                price_change ? <CloseOutlined style={{ "color": "red" }} /> : <div />
            ),
            onFilter: (value, record) => record.price_change === value,
        },
        {
            title: "Promo Price",
            dataIndex: "promo_price",
            key: "promo_price",
            width: "10em",
            filters: getUniqueValuesComma(priceChangeData, 'promo_price') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => a.promo_price.localeCompare(b.promo_price),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },

            onFilter: (value, record) => String(record.promo_price).split(',').map(v => v.trim()).includes(String(value)),
        },
        {
            title: "Sign Kit",
            dataIndex: "sign_kit",
            key: "sign_kit",
            width: "10em",
            filters: getUniqueValuesComma(priceChangeData, 'sign_kit') as FilterDropdownProps['filters'],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => a.sign_kit.localeCompare(b.sign_kit),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, {is_alt_color, is_pdf}) => (
                <span style={{ color: (is_alt_color && (!is_pdf)) ? signKitColorsState.GenericAltColor : 'black' }}>
                {is_pdf ? "PDF" : "Generic"}
                </span>
            ),

            onFilter: (value, record) => String(record.sign_kit).split(',').map(v => v.trim()).includes(String(value)),
        },
        {
            title: "Alt Color",
            dataIndex: "is_alt_color",
            key: "is_alt_color",
            align: "center",
            width: "6em",
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
            ],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => Number(a.is_alt_color) - Number(b.is_alt_color),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { is_alt_color, is_pdf, id }) => (
                readOnlyState.isReadOnly && is_alt_color ? <CheckSquareOutlined /> :
                readOnlyState.isReadOnly && !is_alt_color ? <BorderOutlined /> :
                is_pdf ? (
                    <BorderOutlined style={{ color: 'gray', backgroundColor: 'lightgray', pointerEvents: 'none' }} />
                ) : (
                    (is_alt_color && !is_pdf) ? 
                    <CheckSquareOutlined onClick={() => genericAltSignClickHandler(id, is_alt_color, [store])} /> :
                    <BorderOutlined onClick={() => genericAltSignClickHandler(id, is_alt_color, [store])} />
                )
            ),
            onFilter: (value, record) => {
                try { 
                    if(record.is_alt_color){
                        return value ===true;
                    }else{
                        return value ===false;
                    }
                } catch (error) {
                    return false;
                } 
            }
        },
        {
            title: "PDF Sign",
            dataIndex: "is_pdf",
            key: "is_pdf",
            align: "center",
            width: "6em",
            filters: [
                { text: 'Active', value: true },
                { text: 'Inactive', value: false },
            ],
            filterIcon: filtered => <FilterFilled style={{ color: filtered ? '#fff' : '#808080' }} />,
            sorter: (a: IPriceChange, b: IPriceChange) => Number(a.is_pdf) - Number(b.is_pdf),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { is_pdf, id, is_alt_color }) => (
                readOnlyState.isReadOnly && is_pdf ? <CheckSquareOutlined /> :
                readOnlyState.isReadOnly && !is_pdf ? <BorderOutlined /> :
                is_pdf ? <CheckSquareOutlined onClick={() => { pdfSignClickHandler(id, is_pdf, [store], is_alt_color)}} /> :
                    <BorderOutlined onClick={() => { pdfSignClickHandler(id, is_pdf, [store], is_alt_color)}} />
            ),
            onFilter: (value, record) => {
                try { 
                    if(record.sign_kit ==="PDF"){
                        return value ===true;
                    }else{
                        return value ===false;
                    }
                } catch (error) {
                    return false;
                }         
            }
        },
        {
            title: "Comments",
            dataIndex: "comments",
            key: "comments",
            width: "20em",
            // filters: getUniqueValues(priceChangeData, 'comments') as FilterDropdownProps['filters'], 
            sorter: (a: IPriceChange, b: IPriceChange) => a.comments.localeCompare(b.comments),
            onCell: (_record, _rowIndex) => {
                return {
                    style: { padding: "8px" }
                };
            },
            render: (_, { id, comments }) => (
                <Comments priceChangeId={id} comments={comments} commentsSubmitHandler={commentsSubmitHandler} stores={[store]} />
            ),
            // onFilter: (value, record) => record.comments.indexOf(String(value)) === 0, 
        }
    ]

    const paginationConfig = {
        'defaultPageSize': 100,
        'showSizeChanger': true,
        'pageSizeOptions': [10, 25, 50, 100],
        'showExpandColumn': true
    }

    return (
        <Content
            style={{
                padding: "8px"
            }}
        >
            <Col offset={0} span={24}>
                <Table
                    className='price-change-table'
                    dataSource={priceChangeData}
                    columns={columns}
                    rowKey={(record) => record.id.toString()}
                    sticky
                    pagination={paginationConfig}
                />
            </Col>
        </Content>
    );
}

export default PriceChangeTable;