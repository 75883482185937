import { useState, useEffect, useCallback } from "react";
import { Menu, Layout, Switch} from "antd";
import Stores from "../stores/Stores";
import appConfig from "../../config/config.json";
import EffectiveDate from "../effectiveDate/EffectiveDate";
import { IBatch } from "../../interfaces/batch.interface";
import { findPriceChangeURL } from "../../utils/utils";
import { IServiceURL } from "../../interfaces/serviceURL.interface";
import { Link, useLocation } from "react-router-dom";
import { getEndpoints } from "../../utils/api/config.service";
import { getEffectiveDates } from "../../utils/api/pricechange.service";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import SignKitColorSelector from "../signKitColorSelector/SignKitColorSelector";
import { fetchStores } from "../../reduxStore/reducers/storesSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../reduxStore/reducers/rootReducer";
import ExportToPdf from "../exportToPdf/ExportToPdf";
import { useOktaAuth } from "@okta/okta-react";
import { setReadOnly, setReadWrite } from "../../reduxStore/reducers/readOnlySlice";
import ReadOnlyToggle from "../test/ReadOnlyToggle";

const { Header } = Layout;

interface IStoreChange { (store:string): void; }

interface IEffectiveDateChange { (date:Date):void; }

const AppHeader= ({store, storeChange, effectiveDateChange, effectiveDate} : {store:string, storeChange:IStoreChange, effectiveDateChange:IEffectiveDateChange, effectiveDate:Date}) => {
  const [effectiveDates, setEffectiveDates] = useState<IBatch[]>([]);
  const [serviceURLs, setServiceURLs] = useState<IServiceURL[]>([]);
  const [lastIngestedTimestamp, setLastIngestedTimestamp] = useState('');
  const location = useLocation();
  const { authState }= useOktaAuth();

  const dispatch = useDispatch<any>();
  const { stores, isLoading } = useSelector(
    (state: RootState) => state.stores
  );

  useEffect(() => {
    const isReadOnlyGroup = () => {
      const groups = authState?.accessToken?.claims?.groups || [];

      if (Array.isArray(groups)) {
        return groups.includes("#DM & RVP - All US Regions") ||
               groups.includes("#DM & RVP - Canada")
      } else if (groups instanceof String) {
        return groups == "#DM & RVP - All US Regions" ||
               groups == "#DM & RVP - Canada"
      } else {
        return false;
      }
    }

    if(isReadOnlyGroup()) {
      dispatch(setReadOnly());
    }
  }, [authState])

  const fetchEffectiveDates = useCallback(async (serviceURLs: IServiceURL[]) => {
    if (!serviceURLs.length) return;

    // Fetch all effective dates from backend
    try {
      const priceChangeAPI = findPriceChangeURL(serviceURLs, appConfig.stage); // TODO: to pull env specific when CI is integrated.
      if (!priceChangeAPI) {
        throw new Error("Could not find Price Change API.")
      }

      const response = await getEffectiveDates(priceChangeAPI) as any;
      const data = await response.json();

     setEffectiveDates(data);
     getCurrentEffectiveDate(data);
     getLastIngestedTimestamp(data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getCurrentEffectiveDate = (batches: IBatch[]) => {
    const dayjs = require('dayjs');
    const utc = require('dayjs/plugin/utc');
    const timezone = require('dayjs/plugin/timezone');
    dayjs.extend(utc);
    dayjs.extend(timezone);

    let currentDate = new Date(); 
    let dates = batches.map(batch => {
        let date = new Date(batch.effective_date);
        date.setDate(date.getDate() + 1);
        return date;
    })
    .sort((a, b) => a.getTime() - b.getTime())
    .filter(date => date >= currentDate);
    
    if (dates.length > 0) {
      const convertedDate = dayjs(dates[0]).tz('America/New_York').format('ddd MMM DD YYYY HH:mm:ss');
      effectiveDateChange(convertedDate);
    } else {
      let defaultMostRecentPromoDate = batches.map(batch => {
        let date = new Date(batch.effective_date);
        date.setDate(date.getDate() + 1);
        return date;
      })
      .sort((a, b) => a.getTime() - b.getTime())
      .pop()
      const convertedDate = dayjs(defaultMostRecentPromoDate).tz('America/New_York').format('ddd MMM DD YYYY HH:mm:ss');
      effectiveDateChange(convertedDate);
    }
  }

  const getLastIngestedTimestamp = (batches: IBatch[]) => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    let timestamps = batches.map(batch => {
      const dateString = dayjs(batch.last_ingested_time).format('YYYY-MM-DD HH:mm:ss');
      const utcTime = dayjs.utc(dateString);
      const systemTime = utcTime.local();
      return systemTime.format('MMM DD YYYY hh:mmA');
    });
    
    if (timestamps.length > 0 ) {
      setLastIngestedTimestamp(timestamps[timestamps.length-1]);
    } else {
      setLastIngestedTimestamp('')
    }
  }

  const fetchServiceURLs = async () => {

    try {
      const response = await getEndpoints() as any;
      const data = await JSON.parse(response);

      setServiceURLs(data["endpoints"]);
    } catch (err) {
      console.error(err);
    }
  };


  useEffect(() => {
    const checkIfReloaded = () => {
      if (performance.navigation.type === performance.navigation.TYPE_RELOAD && location.pathname === "/") {
        localStorage.clear()
      }
    };

    checkIfReloaded();
  }, []);

  useEffect(() => {
    dispatch(fetchStores());
  }, [dispatch,authState])

  useEffect(() => {
    fetchServiceURLs();
  }, [authState]);

  useEffect(() => { 
    if (serviceURLs.length > 0) {
      fetchEffectiveDates(serviceURLs);
    }
  }, [fetchEffectiveDates, serviceURLs])

  return (
    <Header 
    title="Carter's Promotions"
    className="site-layout-background"
    style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '1em'}}>
          <div style={{ color: 'white', fontSize: 'larger' }}>Carter's Promotions</div>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <Link to="/">Home</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/promotions">Store Specific Pricing</Link>
            </Menu.Item>
          </Menu>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', gap: '1em', alignItems: 'center'}}>
          {appConfig.stage == "uat" && <ReadOnlyToggle />}
          <span style={{ color: '#F8F8FF' }}>Last Imported: {lastIngestedTimestamp}</span>
          <EffectiveDate effectiveDate={effectiveDate} effectiveDateChange={effectiveDateChange} validEffectiveDates={effectiveDates.map(batch =>  new Date(batch.effective_date).toLocaleDateString('en-US', {timeZone: 'UTC', month: '2-digit', day: '2-digit', year: 'numeric'}))} />
          <Stores stores={stores} handleStoreState={(value:string) => { storeChange(value); }}></Stores>
          {appConfig.showColorSelect && <SignKitColorSelector/>}
          {location.pathname === "/" && <ExportToPdf store={store} effectiveDate={effectiveDate}></ExportToPdf>}
        </div>
      </Header>
  )
}

export default AppHeader;